/* eslint react/prop-types: 0 */
import React, { memo, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import ReactTable, { ReactTableContextsProvider } from '../../core/ReactTable/ReactTable';
import {
  useCycleApplicantsDetailsList,
  useCycleDegrees,
  useCycle,
  useCycleRegions,
  useCycleStatuses,
  useCycleTableModuleInfos,
  useLazyCycleApplicantsDetailsList
} from './CycleHooks';
import { CycleContext, NamespaceContext, TableDataContext } from '../../core/ReactTable/TableContexts';
import { useSelector } from 'react-redux';
import { selectParamsLoaded } from '../../core/ReactTable/paramsSlice';
import { useCycleApplicantsDetailListTable } from './DetailViewHooks';
import { useAccount } from '../../util/Hooks';
import { Box, Center, Grid, Loader, Space } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { CycleDetailViewActionsContainer } from './DetailViewApp';
import { DetailViewSelectedHandler } from './DetailViewSelectedHandler';
import { ApplicantCardFrame } from './ApplicantCard/ApplicantCard';
import { DetailViewStateUpdate, blankDetailViewState, createInitialDetailViewState, detailViewReducer } from './DetailViewState';
import { selectAllSelectedKeys } from '../../core/ReactTable/selectedColumnsSlice';

const applicantCardSpan = { md: 12, lg: 4 }

export function DetailViewTableApp () {
  const { cycleId } = useParams()
  const [state, dispatch] = useReducer(detailViewReducer, blankDetailViewState, createInitialDetailViewState)

  const tableSpan = useMemo(() => state.currentApplicant ? { md: 12, lg: 8 } : 12, [state.currentApplicant])

  const handleCardClose = useCallback(() => {
    dispatch({ type: DetailViewStateUpdate.UpdateCurrentApplicant, payload: null })
  }, [])

  const account = useAccount()

  useEffect(() => {
    const preventDefault = (e) => e.preventDefault()
    document.addEventListener('gesturestart', preventDefault)
    document.addEventListener('gesturechange', preventDefault)

    return () => {
      document.removeEventListener('gesturestart', preventDefault)
      document.removeEventListener('gesturechange', preventDefault)
    }
  }, [])

  return (
    <CycleContext.Provider value={cycleId}>
      <Grid align='stretch'>
        <Grid.Col span={tableSpan}>
          <DetailViewTableActionsBar />
          <DetailViewSelectedHandler account={account} />
          <DetailViewTableLoader cycleId={cycleId} account={account} state={state} dispatch={dispatch} />
        </Grid.Col>
        {!!state.currentApplicant &&
          <Grid.Col span={applicantCardSpan}>
            <Box>
              <ApplicantCardFrame applicantId={state.currentApplicant} cid={cycleId} simple={false} onClose={handleCardClose} />
            </Box>
          </Grid.Col>
        }
      </Grid>
    </CycleContext.Provider>
  )
}

export const DetailViewTableActionsBar = memo(function DetailViewTableActionsBar () {
  // TODO [detail rework] verified first script?
  return (
    <div className='detail-view'>
      <div style={{ float: 'right', height: 0, overflow: 'visible' }}>
        <CycleDetailViewActionsContainer containerName='react-app-container'/>
      </div>
    </div>
  )
})

const DetailViewTableLoader = memo(function DetailViewTableLoader ({ cycleId, account, state, dispatch }) {
  const [moduleInfos, modulesRefreshing] = useCycleTableModuleInfos(cycleId)
  const [statuses, statusesRefreshing] = useCycleStatuses(cycleId)
  const [regions, regionsRefreshing] = useCycleRegions(cycleId)
  const [educationOptions, educationOptionsRefreshing] = useCycleDegrees(cycleId)
  const [cycleDescription, descriptionRefreshing] = useCycle(cycleId)

  const anyLoading = modulesRefreshing || !account.email || statusesRefreshing || regionsRefreshing || educationOptionsRefreshing || descriptionRefreshing

  const statusTiers = useMemo(() => {
    if (!statuses?.items) {
      return []
    }
    const tiersMap = new Map()
    for (const status of statuses.items) {
      if (status.tier) {
        tiersMap.set(status.tier.id, status.tier)
      } else {
        console.warn('Did not find tier where expected in status.', { status, statuses })
      }
    }
    return [...tiersMap.values()]
  }, [statuses])

  return (
    <>
      {!!anyLoading && (
        <>
          <Space h='xxl' />
          <Center>
            <Loader type='bars' />
          </Center>
        </>
      )}
      {!anyLoading && (
        <DetailViewTable
          cycleId={cycleId}
          account={account}
          moduleInfos={moduleInfos}
          statuses={statuses}
          statusTiers={statusTiers}
          regions={regions}
          cycleDescription={cycleDescription}
          educationOptions={educationOptions}
          state={state}
          dispatch={dispatch}
        />
      )}
    </>
  )
})

const DetailViewTable = memo(function DetailViewTable (
  {
    cycleId,
    account,
    moduleInfos,
    statuses,
    statusTiers,
    regions,
    cycleDescription,
    educationOptions,
    namespace = 'cycle-detail-view',
    state,
    dispatch
  }
) {
  const [applicants] = useLazyCycleApplicantsDetailsList(namespace, cycleId)
  const selected = useSelector(state => selectAllSelectedKeys(state, namespace))

  const handleRowClicked = useCallback((row) => {
    console.debug('Detail view table row clicked.', row)
    if (row === undefined) return true
    const applicantId = row.dataset.rowid
    const intApplicantId = parseInt(applicantId)
    const applicant = (applicants?.items?.filter(a => a.id === intApplicantId) ?? [])[0] ?? null
    if (!applicant) {
      console.warn('Unable to find applicant matching clicked id.', { applicantId, intApplicantId, cycleId, applicant, applicants })
      return true
    }

    if (state.currentApplicant === intApplicantId) {
      console.debug('Closing applicant card', { applicantId, cycleId, applicant, row })
      dispatch({ type: DetailViewStateUpdate.UpdateCurrentApplicant, payload: null })
      return true
    } else {
      console.debug('Opening applicant card', { applicantId, cycleId, applicant, row })
      dispatch({ type: DetailViewStateUpdate.UpdateCurrentApplicant, payload: intApplicantId })
    }
  }, [applicants, cycleId, state, dispatch])

  const { columns, defaultFilters, defaultHiddenColumns, ...otherTableProps } = useCycleApplicantsDetailListTable(
    namespace, cycleId, account, moduleInfos, statuses, statusTiers, regions, cycleDescription, educationOptions
  )

  const customRowProps = useMemo(() => {
    const props = {}

    if (selected.length > 0) {
      for (const key of selected) {
        props[key] = { bg: 'blue.1' }
      }
    }

    if (state.currentApplicant) {
      props[state.currentApplicant] = { bg: 'yellow.1' }
    }

    return props
  }, [state.currentApplicant, selected])

  return (
    <ReactTableContextsProvider
      columns={columns}
      namespace={namespace}
      defaultFilters={defaultFilters}
      defaultHiddenColumns={defaultHiddenColumns}
    >
      <CycleDetailTableDataProvider cycleId={cycleId}>
        <ReactTable {...otherTableProps} onRowClick={handleRowClicked} rowProps={customRowProps} />
      </CycleDetailTableDataProvider>
    </ReactTableContextsProvider>
  )
})

function CycleDetailTableDataProvider ({ cycleId, children }) {
  const namespace = useContext(NamespaceContext)
  const loaded = useSelector(state => selectParamsLoaded(state, namespace))
  const [collection, queryRunning] = useCycleApplicantsDetailsList(namespace, cycleId, !loaded)
  const querying = queryRunning || !loaded

  const currentTableDataContext = useMemo(() => {
    console.info('Updating CycleDetailTableDataProvider context memo.', { collection, querying })
    return {
      collection,
      querying
    }
  }, [collection, querying])
  console.debug('Cycle detail table data provider updated.', { collection, querying, namespace, cycleId })
  return (
    <TableDataContext.Provider value={currentTableDataContext}>
      {children}
    </TableDataContext.Provider>
  )
}
