import questionsSlice from './assessment/questionSlice'
import pagesSlice from './assessment/pagesSlice'
import respondentAnswersSlice from './assessment/respondentAnswersSlice'
import assessmentsSlice from './assessment/assessmentsSlice'
import blocksSlice from './assessment/blocksSlice'
import logicSlice from './assessment/logicSlice'
import exportsSlice from './assessment/exportsSlice'
import unpublishedLogicSlice from './build/assessment/EditorComponents/UnpublishedQuestionLogic/unpublishedLogicSlice'
import paramsSlice from './core/ReactTable/paramsSlice'
import selectedColumnsSlice from './core/ReactTable/selectedColumnsSlice';
import tableRowsSlice from './core/ReactTable/tableRowsSlice'
import tableColumnsSlice from './core/ReactTable/tableColumnsSlice'
import { apiSlice } from './api'

const RootReducer = {
  unpublishedLogic: unpublishedLogicSlice,
  logic: logicSlice,
  questions: questionsSlice,
  pages: pagesSlice,
  blocks: blocksSlice,
  respondentAnswers: respondentAnswersSlice,
  assessments: assessmentsSlice,
  exports: exportsSlice,
  params: paramsSlice,
  selectedColumns: selectedColumnsSlice,
  tableRows: tableRowsSlice,
  tableColumns: tableColumnsSlice,
  [apiSlice.reducerPath]: apiSlice.reducer
}

export default RootReducer
