import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import RootReducer from './reducer'
import { apiSlice } from './api';

export const setupStore = preloadedState => {
  return configureStore({
    preloadedState: preloadedState,
    reducer: RootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware()
      .concat(logger)
      .concat(apiSlice.middleware),
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers({
        autoBatch: { type: 'timer', timeout: 10 }
      })
  })
}
